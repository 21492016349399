import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rupeeStringFormat'
})
export class RupeeStringFormatPipe implements PipeTransform {

  transform(value: number): string {
    if (!value) return "0";
    
    const absValue = Math.abs(value);
    // Crores (1 crore = 1.0e+7)
    if (absValue >= 1.0e+7) {
      return (value / 1.0e+7).toFixed(2) + " Cr";
    }
    // Lakhs (1 lakh = 1.0e+5)
    if (absValue >= 1.0e+5) {
      return (value / 1.0e+5).toFixed(2) + " L";
    }
    // Thousands
    if (absValue >= 1.0e+3) {
      return Math.round(value / 1.0e+3) + "K";
    }
    // Default
    return value.toString();
  }
}
